import * as clientConfigs from './configs';
import { ExpErrorPageComponent } from './error-page.component';
const uiLessBuild = process.env.portal === 'uiless';
let routeOptions: any = {
    clientConfigs,
    errorPageUrl: 'error-page',
    envDataFetchConfig: {
        from: 'CDN',
        options: {
            url: '/assets/js/portal.config.json',
        },
    },
};
if (uiLessBuild) {
    routeOptions = {
        errorPageUrl: 'error-page',
    };
}

export const routing = [
    {
        path: 'pages',
        loadChildren: () => import('./spa-lib-wrapper.module').then((m) => m.getExpSPAWrapperModule(true)),
        data: {
            path: 'pages',
            preload: true,
            options: {
                envDataFetchConfig: {
                    from: 'API',
                },
                errorPageUrl: 'error?status=404',
            },
        },
    },
    {
        path: '',
        loadChildren: () => import('./spa-lib-wrapper.module').then((m) => m.getExpSPAWrapperModule(uiLessBuild)),
        data: {
            path: '',
            preload: true,
            options: routeOptions,
        },
    },
    {
        path: 'error-page',
        component: ExpErrorPageComponent,
    },
];
